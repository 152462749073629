export const tableCol = [
  {
    prop: 'nickName',
    label: '分销商名称'
  },
  {
    prop: 'accountName',
    label: '登录账号'
  },
  {
    prop: 'accountLevel',
    label: '当前会员等级'
  }
]
